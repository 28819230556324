<template>
    <datatable :url="ajaxUrl" :createUrl="createUrl" :orderBy="orderBy" :orderAsc="orderAsc" refreshOn="reloadTable" search-placeholder="Search all components..">
        <template #headings>
            <sortableTh v-on:set-order-by="setOrder" :currentOrderBy="orderBy" :orderAsc="orderAsc" orderBy="name">Name</sortableTh>
            <sortableTh v-on:set-order-by="setOrder" :currentOrderBy="orderBy" :orderAsc="orderAsc" orderBy="created_at">Created at</sortableTh>
            <th></th>
        </template>
        <template #rows="{data}">
            <tr v-for="component in data" :key="component.id">
                <td>{{ component.name }}</td>
                <td>{{ component.created_at }}</td>
                <td v-html="component.actions"></td>
            </tr>
        </template>
    </datatable>
</template>

<script setup>
import datatable from '@/components/datatable.vue';
import sortableTh from '@/components/datatable/sortableTh.vue';
import { ref } from "vue";

const props = defineProps({
    ajaxUrl: String,
    createUrl: String,
});

const orderBy = ref('name');
const orderAsc = ref(true);

const setOrder = function (input) {
    if (orderBy.value == input) {
        orderAsc.value = ! orderAsc.value;
    } else {
        orderBy.value = input;
        orderAsc.value = true;
    }
};
</script>
