<template>
    <div class="row mb-4">
        <div class="col-6 d-flex gap-2">
            <select class="form-select" v-model="limit" style="max-width: 70px;">
                <option>10</option>
                <option>25</option>
                <option>50</option>
            </select>
            <div class="input-group">
                <input type="text" class="form-control" v-model="search" :placeholder="searchPlaceholder">
                <span class="input-group-text bg-body">
                    <i class="fa fa-search"></i>
                </span>
            </div>
        </div>
        <div class="col">
            <div class="d-flex justify-content-end align-items-center w-100 h-100">
                <a v-if="createUrl" :href="createUrl" class="btn btn-sm btn-alt-success">
                    <i class="fa fa-plus"></i> Create
                </a>
            </div>
        </div>
    </div>
    <table class="table table-bordered table-striped table-vcenter">
        <thead>
            <tr>
                <slot name="headings"></slot>
            </tr>
        </thead>
        <tbody>
            <tr v-if="loading"><td colspan="99" class="text-center">Loading...</td></tr>
            <tr v-else-if="tableContents.value.data.length == 0"><td colspan="99" class="text-center">Nothing to show</td></tr>
            <slot v-else name="rows" :data="tableContents.value.data"></slot>
        </tbody>
    </table>
    <div class="mt-2" v-if="!loading && tableContents.value.meta && tableContents.value.meta.links.length > 3">
        <ul class="pagination pagination-sm justify-content-end">
            <template v-for="(link, index) in tableContents.value.meta.links" :key="index">
                <li v-if="link.url !== null || (index !== lastLink && index !== 0)" class="page-item" :class="{active: link.active}">
                    <span v-if="link.url === null" class="page-link" v-html="link.label"></span>
                    <button v-else class="page-link" v-on:click.prevent="getData(link.url)" v-html="link.label"></button>
                </li>
            </template>
        </ul>
    </div>
</template>

<script setup>
import { computed, reactive, ref, watch } from "vue";
import { Eventbus } from '@/utilities/eventbus';
import { useFetch } from "@/utilities/useFetch";
import debounce from 'lodash/debounce';

const props = defineProps({
    orderBy: String,
    orderAsc: Boolean,
    refreshOn: String,
    url: String,
    createUrl: {
        type: String,
        required: false,
    },
    filters: Object,
    searchPlaceholder: {
        type: String,
        default: 'Search..',
    },
});

const tableContents = reactive({});
const limit = ref(10);
const search = ref(null);

// this only works if tableContents is reactive
const lastLink = computed(() => tableContents.value.meta?.links.length - 1);

Eventbus.on(props.refreshOn, () => getData());

watch( [search, limit, () => props.orderAsc, () => props.orderBy], () => getData());

if (props.filters) {
    watch(
        props.filters,
        () => getData(),
        { deep: true },
    );
}

let currentURL = null;

const loading = ref(true);

const getData = debounce(async function (url) {
    url = url || currentURL || props.url;
    if (currentURL !== url) {
        currentURL = url;
    }

    const urlToFetch = new URL(url);

    if (search.value) {
        urlToFetch.searchParams.set('search', search.value);
    }

    if (limit.value) {
        urlToFetch.searchParams.set('limit', limit.value);
    }

    if (props.orderBy) {
        let orderBy = props.orderBy;
        if (props.orderAsc === false) {
            orderBy = `-${orderBy}`;
        }
        urlToFetch.searchParams.set('sort', orderBy);
    }

    if (props.filters) {
        for (const [key, value] of Object.entries(props.filters)) {
            if (value) {
                urlToFetch.searchParams.set(key, value);
            }
        }
    }

    loading.value = true;
    const res = await useFetch(urlToFetch);
    tableContents.value = await res.json();
    loading.value = false;
}, 500);

getData(props.url);

defineExpose({
    refresh: getData,
});
</script>
