<template>
    <th v-on:click="$emit('setOrderBy', orderBy)" role="button">
        <slot />
        <span v-if="currentOrderBy == orderBy" v-html="direction"></span>
    </th>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
    currentOrderBy: String,
    orderBy: String,
    orderAsc: Boolean,
});

const direction = computed(() => props.orderAsc ? '↑' : '↓');
</script>
